@tailwind base;
@tailwind components;
@tailwind utilities;

@import './defaults.css';
@import './utilities.css';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 210, 100%, 8%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 218, 100%, 46%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 216, 100%, 60%;
    --chart-2: 152 82% 39%;
    --chart-3: 149, 70%, 44%;
    --chart-4: 42, 99%, 65%;
    --chart-5: 216, 100%, 75%;
    --chart-6: 216, 100%, 50%;
    --chart-7: 83, 87%, 76%;
    --chart-8: 184, 92%, 44%;
    --chart-9: 218, 85%, 33%;
    --chart-10: 220, 92%, 40%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  h1 {
    font-family: var(--gelica), sans-serif;
    line-height: 0.9;
    font-size: 2rem;
  }

  h2 {
    font-family: var(--gelica), sans-serif;
    font-size: 2rem;
    line-height: 95%;
    letter-spacing: -0.32px;
  }

  h3 {
    font-family: var(--gelica), sans-serif;
    line-height: 0.9;
    font-size: 2rem;
    letter-spacing: -0.64px;
  }
  /* Tablet */
  @media (min-width: 568px) {
    h1,
    h2 {
      font-size: 3.125rem;
    }
    h3 {
      font-size: 3.125rem;
      letter-spacing: -1px;
    }
  }

  /* Laptop */
  @media (min-width: 960px) {
    h1,
    h2 {
      font-size: 5rem;
    }
    h3 {
      font-size: 3.75rem;
      letter-spacing: -1.2px;
    }
  }

  /* Desktop */
  @media (min-width: 1440px) {
    h1,
    h2 {
      font-size: 5rem;
    }
  }
}

h4 {
  font-family: var(--gelica), sans-serif;
}

p {
  font-family: var(--proxima-nova), sans-serif;
}

@layer components {
  /* Swap default location of the clock icon on time inputs  */

  input[type='time']::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0px;
  }

  input[type='time'] {
    padding-inline-start: theme(spacing.8);
    position: relative;
  }
}

.marketing-primary-button {
  background-color: #0067ff;
  line-height: 100%;
  color: white;
}

.marketing-primary-button:hover {
  background-color: #2f25a5 !important;
}

.marketing-primary-button:active {
  background-color: #4442fe !important;
}

.marketing-secondary-button {
  background-color: #d0f78f !important;
  font-weight: 700;
  color: #1e0101;
  font-size: 18px;
  line-height: 24px;
}

.marketing-secondary-button:hover {
  background-color: #a3e296 !important;
}

.marketing-secondary-button:active {
  background-color: #21be6e !important;
}

.marketing-tertiary-button {
  background-color: #cddffd;
  color: #0a0a0a;
}

.input-container {
  align-items: center;
  background-color: white;
  border: 1px solid #cdd5df;
  border-radius: 112px;
  padding: 5px;
  overflow: hidden;
  width: 428px;
}

.input-container input {
  border-width: 0;
  color: #697586;
}

.input-container input:focus-visible {
  box-shadow: none;
}
